<template lang="pug">
div(class="container")
  div(class="card")
    header(class="card-header")
      p(class="card-header-title") Filtered errors
    div(class="card-content")
      b-error(v-if="error" :error="error" class="error")
      div(class="content")
        div(class="columns")
          b-field(label="System" class="column")
            b-select(
              v-model="selectedSystem"
              placeholder="System"
            )
              option(
                v-for="system in systems"
                :value="system"
                :key="system._id") {{ system.name }}

          b-field(
            label="Filter"
             class="column"
            v-if="selectedSystem")
            div(
              v-for="(filter, index) in selectedSystem.filters" :key="index" class="control")
                b-checkbox(v-model="filter.active") {{ filter.name }}

        nx-table(
          :key="JSON.stringify(selectedSystem ?? 'all')"
          :urlFetch="urlFetch"
          urlDelete="entry/delete"
          ref="nxTable"
          :selectedColumns="[\
            'type',\
            'value',\
            'timestamp',\
            'creationDate',\
          ]"
        )
          template(#column-system="row") {{ row.system.name }}
          template(#column-creationDate="row") {{ formattedDate(row.creationDate) }}
          template(#column-timestamp="row") {{ formattedDate(row.timestamp) }}

    footer(class="card-footer")
</template>

<script>
import moment from 'moment';
import nxTable from '@nx-t/vue-components/vue2/nxTableOld';
import bError from '@nx-t/vue-components/vue2/bError';

const axios = require('redaxios').default;

export default {
  components: {
    nxTable,
    bError,
  },
  data() {
    return {
      error: null,
      systems: [],
      selectedSystem: null,
    };
  },
  computed: {
    selectedFilters() {
      const filters = this.selectedSystem?.filters.filter((filter) => filter.active);
      if (!filters) {
        return [];
      }

      return filters.map((filter) => filter._id);
    },
  },
  created() {
    this.fetchSystems();
  },
  methods: {
    fetchSystems() {
      this.systems = null;

      axios
        .get('system/list')
        .then((response) => {
          if (response.data && response.data.success) {
            this.columns = [];
            const { list } = response.data;

            list.forEach((system) => {
              system.filters.map((_filter) => {
                const filter = _filter;
                filter.active = true;
                return filter;
              });
            });

            this.systems = list;
          } else if (response.data && response.data.errorMessage) {
            this.error = response.data.errorMessage;
          } else {
            this.error = response.data;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.error = error.response.data;
          } else {
            this.error = error.toString();
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    urlFetch(sortBy, order, page, perPage, filters) {
      return axios
        .post('error/filtered', {
          system: this.selectedSystem?._id,
          selectedFilters: this.selectedFilters,
          sortBy,
          order,
          page,
          perPage,
          filters: JSON.stringify(filters),
        });
    },
    formattedDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss');
    },
  },
};

</script>
