<template lang="pug">
div(class="container")
  div(class="card")
    header(class="card-header")
      p(class="card-header-title") Heartbeats
    div(class="card-content")
      div(class="content")
        nx-table(
          urlFetch="heartbeat/list"
          ref="nxTable"
          :selectedColumns="[\
            'system',\
            'status',\
            'lastContact',\
          ]"
        )
          template(#column-system="row") {{ row.system.name }}

    footer(class="card-footer")
</template>

<script>
import nxTable from '@nx-t/vue-components/vue2/nxTableOld';

export default {
  components: {
    nxTable,
  },
};

</script>
