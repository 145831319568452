import Vue from 'vue';
import Router from 'vue-router';
import heartbeats from './pages/heartbeats';
import filteredErrorList from './pages/error-filtered';
import latestErrorList from './pages/error-latest';
import systemList from './pages/system';
import filterList from './pages/filter';
import entryList from './pages/entry';

Vue.use(Router);

export default new Router({
  mode: 'hash',
  routes: [
    { path: '/', component: heartbeats },
    { path: '/filtered/', component: filteredErrorList },
    { path: '/latest/', component: latestErrorList },
    { path: '/systems/', component: systemList },
    { path: '/filters/', component: filterList },
    { path: '/entries/', component: entryList },
  ],
});
