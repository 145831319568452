<template lang="pug">
div#page
  div#header
    b-navbar(fixed-top shadow spaced)
      template(#brand)
        b-navbar-item(tag="router-link" :to="{ path: '/' }")
          img(
            src="/bezahlde.gif"
            alt=""
            srcset=""
          )
      template(#start)
        b-navbar-item(tag="router-link" :to="{ path: '/' }") Heartbeats
        b-navbar-dropdown(label="Errors")
          b-navbar-item(tag="router-link" :to="{ path: '/filtered' }") Filtered
          b-navbar-item(tag="router-link" :to="{ path: '/latest' }") Latest
        b-navbar-dropdown(label="Config")
          b-navbar-item(tag="router-link" :to="{ path: '/systems' }") Systems
          b-navbar-item(tag="router-link" :to="{ path: '/filters' }") Filters
        b-navbar-dropdown(label="DB")
          b-navbar-item(tag="router-link" :to="{ path: '/entries' }") Entries

      template(#end)
        b-navbar-item(tag="div")
          a(:href="`/login?redirectUrl=${currentRoute}`")
            b-button(
              label="Login"
              icon-left="login"
            )
  router-view(class="is-fluid")

</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      currentRoute: encodeURIComponent(window.location.toString()),
    };
  },
  watch: {
    $route() {
      this.currentRoute = encodeURIComponent(window.location.toString());
    },
  },
});
</script>
