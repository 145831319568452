<template lang="pug">
div(class="container")
  div(class="card")
    header(class="card-header")
      p(class="card-header-title") Systems
    div(class="card-content")
      div(class="content")
        nx-table(
          urlAdd="system/add"
          urlEdit="system/edit"
          urlFetch="system/list"
          urlDelete="system/delete"
          ref="nxTable"
          :selectedColumns="[\
            'name',\
            'expected',\
            'notificationAfter',\
          ]"
          @fillEdit="fill($event)"
        )
          template(#column-filters="row") {{ row.filters.map(f => `${f.name}: ${f.regex}`).join(", ") }}
          template(#column-lastHeartbeat="row") {{ row.lastHeartbeat ? formattedDate(row.lastHeartbeat) : null  }}
          template(#column-email="row") {{ formatMail(row.email) }}
          template(#form="props")
            div(class="modal-card" style="width: auto")
              header(class="modal-card-head")
                p(class="modal-card-title") {{ props.modeAdd ? 'Add system' : 'Edit system' }}
              section(class="modal-card-body")
                b-field(label="Name")
                  b-input(
                    type="string"
                    v-model="formData.name"
                    placeholder="Name"
                    required
                  )

                b-field(label="Expected [m]")
                  b-input(
                    type="number"
                    v-model="formData.expected"
                    placeholder="Expected [m]"
                    required
                  )

                b-field(label="Notification after [m]")
                  b-input(
                    type="number"
                    v-model="formData.notificationAfter"
                    placeholder="Notification after [m]"
                    required
                  )

                b-field(label="Error Ratelimit")
                  b-input(
                    type="number"
                    v-model="formData.errorRatelimit"
                    placeholder="Error Ratelimit"
                    required
                  )

                b-field(label="Email to notify")
                  b-taginput(
                    type="email"
                    v-model="formData.email.notify"
                    placeholder="email"
                  )

                b-field(label="Email for error notifications")
                  b-taginput(
                    type="email"
                    v-model="formData.email.add"
                    placeholder="email"
                  )

                b-field(label="Email for error summary")
                  b-taginput(
                    type="email"
                    v-model="formData.email.summary"
                    placeholder="email"
                  )

                b-field(label="key" v-if="!props.modeAdd")
                  b-input(
                    type="string"
                    v-model="formData.key"
                    placeholder="key"
                    required
                  )

              footer(class="modal-card-foot")
                b-button(
                  label="Close"
                  icon-left="close"
                  @click="$refs.nxTable.close()"
                )
                b-button(
                  label="Save"
                  icon-left="plus"
                  type="is-primary"
                  @click="$refs.nxTable.save(formData)"
                )
                b-message(v-if="props.error" title="error") {{ props.error }}

    footer(class="card-footer")
</template>

<script>
import moment from 'moment';
import nxTable from '@nx-t/vue-components/vue2/nxTableOld';

export default {
  components: {
    nxTable,
  },
  data() {
    return {
      formData: {
        name: '',
        expected: '',
        notificationAfter: '',
        errorRatelimit: '',
        email: {
          add: [],
          notify: [],
          summary: [],
        },
      },
    };
  },
  methods: {
    fill(editData) {
      this.formData = editData;
      if (!this.formData.email) {
        this.formData.email = {
          add: [],
          notify: [],
          summary: [],
        };
      }
    },
    formattedDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss');
    },
    formatMail(email) {
      return `add: ${email.add.join(', ')}; notify: ${email.notify.join(', ')}; summary: ${email.summary.join(', ')}`;
    },
  },
};

</script>
