<template lang="pug">
div(class="container")
  div(class="card")
    header(class="card-header")
      p(class="card-header-title") Latest errors
    div(class="card-content")
      div(class="content")
        nx-table(
          urlFetch="error/latest"
          ref="nxTable"
          :selectedColumns="[\
            'system',\
            'value',\
            'errorDate',\
          ]"
        )
          template(#column-system="row") {{ row.system.name }}
          template(#column-errorDate="row") {{ formattedDate(row.errorDate) }}

    footer(class="card-footer")
</template>

<script>
import moment from 'moment';
import nxTable from '@nx-t/vue-components/vue2/nxTableOld';

export default {
  components: {
    nxTable,
  },
  methods: {
    formattedDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss');
    },
  },
};

</script>
