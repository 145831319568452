import { render, staticRenderFns } from "./heartbeats.vue?vue&type=template&id=725b8c34&lang=pug"
import script from "./heartbeats.vue?vue&type=script&lang=js"
export * from "./heartbeats.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_webpack@5.97.1__lodash@4.17.21_pug@3.0.3_vue-templ_f1007ed1cae8b5b9b1773decaad6cbd6/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports