<template lang="pug">
div(class="container")
  div(class="card")
    header(class="card-header")
      p(class="card-header-title") Filters
    div(class="card-content")
      b-error(v-if="error" :error="error" class="error")
      div(class="content")
        nx-table(
          urlAdd="filter/add"
          urlEdit="filter/edit"
          urlFetch="filter/list"
          urlDelete="filter/delete"
          ref="nxTable"
          :selectedColumns="[\
            'name',\
            'regex',\
            'system',\
          ]"
          @fillEdit="fill($event)"
        )
          template(#column-system="row") {{ row.system.name }}
          template(#form="props")
            div(class="modal-card" style="width: auto")
              header(class="modal-card-head")
                p(class="modal-card-title") {{ props.modeAdd ? 'Add filter' : 'Edit filter' }}
              section(class="modal-card-body")
                b-field(label="System")
                  b-select(
                    v-model="formData.system"
                    placeholder="System"
                    required
                  )
                    option(
                      v-for="system in systems"
                      :value="system._id"
                      :key="system._id") {{ system.name }}
                b-field(label="Name")
                  b-input(
                    type="string"
                    v-model="formData.name"
                    placeholder="Name"
                    required
                  )

                b-field(label="Regex")
                  b-input(
                    type="string"
                    v-model="formData.regex"
                    placeholder="Regex"
                    required
                  )

              footer(class="modal-card-foot")
                b-button(
                  label="Close"
                  icon-left="close"
                  @click="$refs.nxTable.close()"
                )
                b-button(
                  label="Save"
                  icon-left="plus"
                  type="is-primary"
                  @click="$refs.nxTable.save(formData)"
                )
                b-message(v-if="props.error" title="error") {{ props.error }}

    footer(class="card-footer")
</template>

<script>
import nxTable from '@nx-t/vue-components/vue2/nxTableOld';
import bError from '@nx-t/vue-components/vue2/bError';

const axios = require('redaxios').default;

export default {
  components: {
    nxTable,
    bError,
  },
  data() {
    return {
      systems: [],
      formData: {
        system: null,
        name: '',
        regex: '',
      },
      error: null,
    };
  },
  created() {
    this.fetchSystems();
  },
  methods: {
    fetchSystems() {
      this.systems = null;

      axios
        .get('system/list')
        .then((response) => {
          if (response.data && response.data.success) {
            this.columns = [];
            this.systems = response.data.list;
          } else if (response.data && response.data.errorMessage) {
            this.error = response.data.errorMessage;
          } else {
            this.error = response.data;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.error = error.response.data;
          } else {
            this.error = error.toString();
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    fill(editData) {
      this.formData = editData;
      this.formData.system = editData.system._id;
    },
  },
};

</script>
